<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="900px"
    :title="$t('deviceList.sedinittaRetrieve')"
    :close-on-click-modal="false"
    :visible.sync="show"
  >
    <div v-if="errorTip" style="text-align: center;">
      {{ errorTip }}
    </div>
    <div class="fileMain" v-else>
      <div class="prePath">
        <span>当前索引：{{ pathStr }}</span>
        <el-button type="text" class="link linkUp" @click="toPrev" v-show="pathArray.length > 1">
          [上级目录]
        </el-button>
      </div>
      <div class="table-wrap">
        <el-table height="100%" :data="tableData">
          <el-table-column label="名称" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <el-button type="text" class="link link0" @click="toNext(row)" v-if="row.isDir">
                {{ row.name }}
              </el-button>
              <el-button type="text" class="link link1" @click="downLoad(row)" v-else>
                {{ row.name }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="大小" width="110" show-overflow-tooltip>
            <template slot-scope="{ row }">
              {{ row.isDir ? '-' : row.sizeString }}
            </template>
          </el-table-column>
          <el-table-column
            prop="dateModifiedString"
            label="修改日期"
            width="260"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
        <form id="downForm" :action="downloadUrl" method="post" target="downloadIframe">
          <input type="hidden" name="rootPath" value="" />
          <input type="hidden" name="password" value="" />
        </form>
        <!-- 用于打开下载窗口 -->
        <iframe name="downloadIframe" style="display:none" />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import { decrypt } from '@/lib/crypto.js'
import axios from '@/lib/request.js'
export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      errorTip: '',
      retrieveInfo: {},
      tableData: [],
      pathArray: [{ name: '', url: '' }],
      downloadUrl: ''
    }
  },
  computed: {
    pathStr() {
      const pathArray = this.pathArray.map(a => a.name)
      let str = pathArray.join('/') + '/'
      return str
    }
  },
  created() {
    try {
      let data = decrypt(this.currentItem.result)
      data = JSON.parse(data)
      this.downloadUrl = `${location.protocol}//${data.ip || data.innerIp}:${
        data.port
      }/file/download`
      this.retrieveInfo = data
      window.addEventListener('beforeunload', this.clear)
      this.getFileList()
    } catch (error) {
      this.errorTip = error
    }
  },
  //销毁 断开连接
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.clear)
    this.clear()
  },
  methods: {
    clear() {
      const url = `${location.protocol}//${this.retrieveInfo.ip || this.retrieveInfo.innerIp}:${
        this.retrieveInfo.port
      }/file/close`
      axios.get('', {
        baseURL: url,
        params: { password: this.retrieveInfo.password },
        options: { isSelfBaseURL: true }
      })
    },
    async getFileList(rootPath = '', nextName) {
      try {
        this.loading = true
        const password = this.retrieveInfo.password
        const res = await axios.get('', {
          baseURL: `${location.protocol}//${this.retrieveInfo.ip || this.retrieveInfo.innerIp}:${
            this.retrieveInfo.port
          }/file/list`,
          params: { password, rootPath },
          options: { isSelfBaseURL: true }
        })
        this.tableData = res?.data || []
        if (nextName) {
          this.pathArray.push({ name: nextName, url: rootPath })
        } else if (this.pathArray.length > 1) {
          this.pathArray.pop()
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    toPrev() {
      let prePath = this.pathArray[this.pathArray.length - 2].url
      this.getFileList(prePath)
    },
    toNext(row) {
      this.getFileList(row.url, row.name)
    },
    downLoad(row) {
      document.getElementById('downForm').rootPath.value = row.url
      document.getElementById('downForm').password.value = this.retrieveInfo.password
      document.getElementById('downForm').submit()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 0 !important;
}
.fileMain {
  width: 98%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 80vh;
  .prePath {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: #000;
      font-size: 16px;
      margin-right: 20px;
    }
  }
  .table-wrap {
    flex: 1;
  }
}
::v-deep .el-table__cell {
  padding: 0 !important;
}
.link {
  color: blue;
  padding-left: 22px;
  background: url('~@/assets/img/file.png') left center no-repeat;
  background-size: 16px 16px;
  &.link0 {
    background-image: url('~@/assets/img/dir.png');
  }
  &.linkUp {
    background-image: url('~@/assets/img/up.png');
  }
}
</style>
