<template>
  <div>
    <el-dialog
      v-elDragDialog
      width="700px"
      :title="isCreated ? $t('appList.uploadApp') : $t('appList.updateApp')"
      :close-on-click-modal="false"
      :visible.sync="show"
      class="add-app"
    >
      <el-form
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        :element-loading-text="$t('appList.uploadAppLoading')"
        :model="form"
        :rules="rules"
        size="small"
        ref="form"
        @submit.native.prevent
      >
        <el-form-item :label="$t('appList.appIcon')" prop="icon">
          <div class="flex">
            <el-upload
              action="null"
              ref="appIconUpload"
              class="avatar-uploader"
              style="width:100%"
              accept=".png,.jpg,.gif"
              :show-file-list="false"
              :multiple="false"
              :auto-upload="false"
              :on-remove="() => (form.icon = '')"
              :on-change="file => onFilechange(file, 'icon')"
            >
              <img v-if="iconPathUrl" :src="iconPathUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="tips">
              <p>{{ $t('appList.explain') }}:</p>
              <p>{{ $t('appList.imgSize') }}</p>
              <p>{{ $t('appList.imgFormat') }}</p>
            </div>
          </div>
        </el-form-item>

        <el-form-item :label="$t('appList.appName')" prop="appName">
          <el-input v-model="form.appName" :placeholder="$t('appList.enterAppName')" />
        </el-form-item>

        <el-form-item :label="$t('appList.appInfo')" prop="appDesc">
          <el-input
            type="textarea"
            rows="2"
            resize="none"
            v-model="form.appDesc"
            :placeholder="$t('appList.enterAppInfo')"
          />
        </el-form-item>
        <el-form-item :label="$t('appList.appScreenshot')">
          <div class="flex">
            <el-upload
              action="null"
              accept=".png,.jpg"
              list-type="picture-card"
              :auto-upload="false"
              :on-preview="handlePictureCardPreview"
              :file-list="appImagePathUrl"
              :on-remove="handleRemove"
              :on-change="onFileschange"
            >
              <i class="el-icon-plus"></i>
            </el-upload>

            <div class="tips">
              <p>{{ $t('appList.appScreenshot') }}:</p>
              <p>{{ $t('appList.appScreenshotExplain') }}</p>
            </div>
          </div>
        </el-form-item>

        <el-form-item :label="$t('uploadFiles')" prop="apk">
          <el-upload
            accept=".apk,.exe,.msi"
            action="null"
            :on-change="file => onFilechange(file, 'apk')"
            :on-remove="() => (form.apk = '')"
            :show-file-list="true"
            :multiple="false"
            :auto-upload="false"
            ref="fileUpload"
            name="xls"
          >
            <el-button @click="$refs.fileUpload.clearFiles()" type="primary"
              >{{ $t('deviceModal.selectFile') }}
            </el-button>
          </el-upload>
        </el-form-item>

        <!-- <el-form-item :label="$t('appList.applicableModel')" prop="applicableModel">
          <el-select
            multiple
            v-model="form.applicableModel"
            :placeholder="$t('appList.selectApplicableModel')"
          >
            <el-option
              v-for="item in modelList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item> -->

        <el-form-item :label="$t('deviceType.signFileName')" prop="signFile">
          <el-select v-model="form.signFile" :placeholder="$t('placeholder')">
            <el-option
              v-for="item in signList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('appList.automaticUpdate')" prop="automaticUpdate">
          <el-radio-group v-model="form.automaticUpdate">
            <el-radio :label="1">{{ $t('yes') }}</el-radio>
            <el-radio :label="0">{{ $t('no') }}</el-radio>
          </el-radio-group>
          <el-tooltip
            class="item"
            :content="$t('appList.automaticUpdate')"
            placement="right"
            effect="light"
          >
            <i class="el-icon-question " style="margin-left:20px;font-size:16px;"></i>
          </el-tooltip>
        </el-form-item>

        <el-form-item :label="$t('appList.versionDesc')" prop="versionDesc">
          <el-input
            type="textarea"
            rows="4"
            resize="none"
            v-model="form.versionDesc"
            :placeholder="$t('appList.enterVersionDesc')"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">{{ $t('cancel') }}</el-button>
        <el-button :disabled="loading === true" type="primary" @click="submitForm(form)">{{
          $t('save')
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog v-elDragDialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  components: {},
  props: ['modelList'],
  data() {
    const rules = {
      icon: [{ required: true, message: this.$t('appList.enterAppIcon') }],
      appName: [
        { required: true, message: this.$t('appList.enterAppName') },
        { max: 50, message: this.$t('appList.maxLength'), trigger: 'blur' }
      ],
      appImageFiles: [{ required: true, message: this.$t('deviceModal.pleaseUploadFile') }],
      apk: [{ required: true, message: this.$t('appList.uploadaApk') }],
      applicableModel: [
        { required: true, trigger: 'change', message: this.$t('appList.selectApplicableModel') }
      ],
      signFile: [
        { required: true, trigger: 'change', message: this.$t('deviceType.selectSignFileName') }
      ]
    }

    return {
      loading: false,
      fileList: [],
      iconPathUrl: '',
      appIconImageUrl: '',
      dialogImageUrl: '',
      dialogVisible: false,
      form: {
        icon: '',
        appName: '',
        appIntro: '',
        appImageFiles: '',
        file: '',
        apk: '',
        // applicableModel: [],
        versionDesc: '',
        automaticUpdate: 0,
        signFile: ''
      },
      rules,
      appImagePathUrl: [],
      appImageUrls: [],
      signList: []
    }
  },
  created() {
    this.getSign()
    if (!this.isCreated) {
      this.rules.icon = undefined
      this.rules.appImageFiles = undefined
      this.rules.apk = undefined
      this.rules = this.rules
    }

    if (this.currentItem && this.currentItem.appId) {
      this.getDetail()
    }
  },
  methods: {
    async getSign() {
      try {
        let { result } = await this.$api.deviceManagement.getSignList()
        this.signList = result || []
      } catch (e) {
        console.log(e)
      }
    },
    async getDetail() {
      try {
        const res = await this.$api.appManagement.appGet({ appId: this.currentItem.appId })
        const item = res.result

        Object.keys(this.form).forEach(key => {
          this.form[key] = item[key]
        })
        this.$set(this.form, 'version', item.versionCode)
        this.$set(this.form, 'signFile', item.signFile)
        this.$set(this.form, 'appDesc', item.appDesc)
        this.$set(this.form, 'versionDesc', item.releaseNote)
        this.$set(
          this.form,
          'automaticUpdate',
          this.currentItem.automaticUpdate == null ? 0 : this.currentItem.automaticUpdate
        )
        this.iconPathUrl = item.iconPathUrl
        this.appImagePathUrl = item.appImagePathUrl?.split(',').map(item => ({ url: item }))
        this.appImageUrls = this.currentItem.appImagePathUrl?.split(',')
      } catch (err) {
        console.log(err)
      }
    },
    onFileschange(res, files) {
      // 排除新增的本地地址（包含 blob:  类似：blob:http://localhost:8085/c5787c66-bdf8-4ee4-af49-a542c99a2684）
      this.appImageUrls = files
        .filter(item => item.url && !item.url.includes('blob:'))
        .map(item => item.url)
      this.form.appImageFiles = files.map(item => item.raw)
    },
    upLoadHandler() {
      this.$refs.upload.submit()
    },
    onFilechange(file, key) {
      if (key === 'icon') {
        this.iconPathUrl = URL.createObjectURL(file.raw)
      }
      if (key == 'apk') {
        //只能上传 .apk ,.exe,.msi文件
        let index = file.raw.name.lastIndexOf('.')
        //获取后缀
        let suffix = file.raw.name.substr(index + 1) || ''

        if (!['exe', 'apk', 'msi'].includes(suffix.toLowerCase())) {
          this.$refs.fileUpload.clearFiles()
          this.$message.warning(this.$t('appList.uploadFileWarnTip'))
          return
        }
      }
      this.form[key] = file.raw
    },
    handleRemove(file, fileList) {
      // 排除新增的本地地址（包含 blob:  类似：blob:http://localhost:8085/c5787c66-bdf8-4ee4-af49-a542c99a2684）
      this.appImageUrls = fileList
        .filter(item => item.url && !item.url.includes('blob:'))
        .map(item => item.url)
      this.form.appImageFiles = fileList.map(item => item.raw)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    submitForm(data) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true

          try {
            const formData = new FormData()

            for (const i in this.form) {
              if (i === 'appImageFiles' && this.form[i] && this.form[i].length) {
                this.form[i].forEach(item => {
                  formData.append(i, item)
                })
              } else {
                this.form[i] && formData.append(i, this.form[i])
              }
            }

            if (this.appImageUrls?.length) {
              this.appImageUrls.forEach(item => {
                formData.append('appImageUrls ', item)
              })
            }

            if (this.currentItem && this.currentItem.appId) {
              formData.append('appId', this.currentItem.appId)

              await this.$api.appManagement.appUpdate(formData)
              this.$message.success(this.$t('updateSuccess'))
            } else {
              await this.$api.appManagement.appAdd(formData)
              this.$message.success(this.$t('appList.uploadaSuccess'))
            }

            setTimeout(() => {
              this.$emit('getData')
              this.form.applicableModel = []
              this.show = false
              this.loading = false
            }, 1000)
          } catch (e) {
            console.log(e)
          } finally {
            setTimeout(() => {
              this.loading = false
            }, 3000)
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner i {
  font-size: 30px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 120px !important;
  height: 120px !important;
  line-height: 120px;
}

::v-deep .el-upload--picture-card {
  width: 120px !important;
  height: 120px !important;
  line-height: 120px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item-status-label i {
  position: absolute;
  left: 14px;
}

::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .el-select {
  width: 100%;
}

.avatar-uploader .el-upload:hover {
  border-color: #ef2b23;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

.flex {
  display: flex;
  align-items: center;

  .tips {
    line-height: 20px;
    margin-left: 20px;
  }
}
</style>
