<template>
  <page v-loading="loading" :borderBottom="true" class="page-container">
    <div slot="headerLeft">
      <span class="spanItem active">{{ $t('appList.appDetails') }}</span>
    </div>
    <div slot="headerRight" class="flex" style="align-items: center;">
      <el-button size="small" type="primary" @click="$router.go(-1)">
        {{ $t('backList') }}</el-button
      >
    </div>

    <div class="mainWrap">
      <div class="row">
        <span class="label">{{ operateName }} {{ $t('publishApp.appOf') }}:</span>
        <div class="content">
          <span>{{ currentItem.appInfos.length }} {{ $t('publishApp.ge') }}</span>
          <div class="row viewer" v-viewer>
            <div class="info" v-for="item in currentItem.appInfos" :key="item.appId">
              <div class="tagWrap">
                <el-tag v-if="item.powerBoot" size="mini" type="primary">{{
                  $t('deviceList.selfStart')
                }}</el-tag>
                <el-tag v-if="item.showApp" size="mini">{{
                  $t('deviceList.dominateScreen')
                }}</el-tag>
              </div>

              <img :src="item.iconPathUrl" />
              <div class="name" :title="item.appName">{{ item.appName }}</div>
              <div class="info-b">
                <span>{{ $utils.bytesToSize(item.appSize) }}</span>
                <span v-if="operateName === $t('publishApp.release')"
                  >{{ $t('deviceList.versionNo') }} {{ item.versionName }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <span class="label">{{ operateName }}{{ $t('operateList.mode') }}:</span>
        <div class="content">
          <span>{{ item.publishWayStr }}</span>
          <div class="row" v-if="item.publishWay !== 4">
            <span v-for="item in list" :key="item.value">[ {{ item.label }} ]</span>
          </div>
        </div>
      </div>
      <div class="row">
        <span class="label">{{ $t('operateList.associatedDevices') }}:</span>
        <span>{{ currentItem.sumDevice }}{{ $t('adminHomePage.tower') }}</span>
        <el-link @click="downloadList" type="primary">{{
          $t('operateList.downloadDeviceList')
        }}</el-link>
      </div>
    </div>

    <div class="bottomWrap">
      <div class="switchHead">
        <el-radio-group v-model="currentView">
          <el-radio-button label="DeviceView">{{ $t('operateList.viewByDevice') }}</el-radio-button>
          <el-radio-button label="AppView">{{ $t('operateList.viewByApp') }}</el-radio-button>
        </el-radio-group>
      </div>

      <component :faterItem="currentItem" :operateType="operateType" :is="currentView" />
    </div>
  </page>
</template>

<script>
import DeviceView from './components/DeviceView'
import AppView from './components/AppView'

export default {
  components: {
    DeviceView,
    AppView
  },
  data() {
    return {
      currentView: 'DeviceView',
      loading: false,
      item: {},
      list: [],
      types: [
        {
          value: 1,
          label: this.$t('marketManagement.publishGroup')
        },
        {
          value: 2,
          label: this.$t('publishApp.publishBySN')
        },
        {
          value: 3,
          label: this.$t('publishApp.publishDevice')
        },
        {
          value: 4,
          label: this.$t('publishApp.fullRelease')
        }
      ]
    }
  },
  created() {
    if (this.currentItem) {
      const item = { ...this.currentItem }
      const e = this.types.find(e => e.value === item.publishWay)
      item.publishWayStr =
        this.operateType === 2
          ? e.label.replace(this.$t('publishApp.release'), this.$t('publishApp.uninstall'))
          : e.label
      this.item = item
    }
    const publishObjectIds = (this.currentItem.publishObjectIds || '').split(',')
    if (this.currentItem.publishWay === 1) {
      this.getGrouplName(publishObjectIds)
    }
    if (this.currentItem.publishWay === 2) {
      this.getDeviceName(publishObjectIds)
    }
    if (this.currentItem.publishWay === 3) {
      this.getModelName(publishObjectIds)
    }
  },
  computed: {
    currentItem() {
      let details = sessionStorage.getItem('currentAppDetail')
      if (details) {
        details = JSON.parse(details)
        return { ...details, appInfos: details.appInfos || [] }
      }
      return {}
    },
    operateName() {
      return this.operateType === 2
        ? this.$t('publishApp.uninstall')
        : this.$t('publishApp.release')
    },
    operateType() {
      return +this.$route.params.type
    }
  },
  methods: {
    async downloadList() {
      const loading = this.$loading()
      try {
        const res = await this.$api.appManagement.downloadList({
          publishId: this.currentItem.publishId,
          spId: this.currentItem.spId
        })

        this.$utils.downFile(res)
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          loading.close()
        }, 1000)
      }
    },
    async getGrouplName(publishObjectIds) {
      try {
        this.loading = true
        const res = await this.$api.deviceManagement.getGrouplName(publishObjectIds)
        this.list = res.result.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    async getDeviceName(publishObjectIds) {
      try {
        this.loading = true
        const res = await this.$api.deviceManagement.getDeviceName({ snList: publishObjectIds })
        this.list = res.result.map(item => {
          return {
            label: item.sn,
            value: item.id
          }
        })
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    async getModelName(publishObjectIds) {
      try {
        this.loading = true
        const res = await this.$api.deviceManagement.getModelName(publishObjectIds)
        this.list = res.result.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      } catch (err) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page-container {
  overflow: auto;
}
.el-button {
  padding: 6px 15px;
}

.mainWrap {
  .row {
    display: flex;

    img {
      width: 50px;
      height: 50px;
    }

    & + .row {
      margin-top: 18px;
    }
    .label {
      width: 180px;
      flex-shrink: 0;
    }
    .row {
      flex-wrap: wrap;
      margin-top: 10px;
      span + span {
        margin-left: 2px;
      }
    }
    .info {
      width: 200px;
      flex-wrap: wrap;
      word-break: break-word;
      border: 1px solid #ccc;
      padding: 10px 0;
      margin-right: 5px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      .name {
        font-size: 15px;
        line-height: 34px;
        height: 34px;
        font-weight: bold;
        text-align: center;
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .info-b {
        display: flex;
        text-align: center;

        span {
          font-size: 13px;
        }
        span + span {
          margin-left: 10px;
        }
      }

      .tagWrap {
        position: absolute;
        left: 4px;
        top: 0;
        display: flex;
        flex-direction: column;
        ::v-deep .el-tag {
          width: 50px;
          text-align: center;
          margin-top: 6px;
          font-weight: bold;
        }
      }
    }
  }
  ::v-deep .el-link {
    margin-left: 5px;
  }
}

.bottomWrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  .switchHead {
    display: flex;
    justify-content: center;
  }
}
</style>
