<template>
  <div>
    <el-dialog
      v-elDragDialog
      width="850px"
      :title="isCreated ? $t('appList.uploadApp') : $t('appList.updateApp')"
      :close-on-click-modal="false"
      :visible.sync="show"
      class="add-app"
    >
      <el-form
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        :element-loading-text="$t('appList.uploadAppLoading')"
        :model="form"
        :rules="rules"
        size="small"
        ref="form"
        label-width="130px"
        @submit.native.prevent
      >
        <el-form-item :label="$t('appList.appIcon')" prop="icon">
          <div class="flex">
            <el-upload
              action="null"
              ref="appIconUpload"
              class="avatar-uploader"
              accept=".png,.jpg,.gif"
              :show-file-list="false"
              :multiple="false"
              :auto-upload="false"
              :on-remove="() => (form.icon = '')"
              :on-change="file => onFilechange(file, 'icon')"
            >
              <img v-if="iconPathUrl" :src="iconPathUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="tips">
              <p>{{ $t('appList.explain') }}:</p>
              <p>{{ $t('appList.imgSize') }}</p>
              <p>{{ $t('appList.imgFormat') }}</p>
            </div>
          </div>
        </el-form-item>

        <el-form-item :label="$t('appList.appName')" prop="appName">
          <el-input v-model.trim="form.appName" :placeholder="$t('appList.enterAppName')" />
        </el-form-item>

        <el-form-item :label="$t('appList.appInfo')" prop="appDesc">
          <el-input
            type="textarea"
            rows="4"
            resize="none"
            v-model="form.appDesc"
            :placeholder="$t('appList.enterAppInfo')"
          />
        </el-form-item>

        <el-form-item :label="$t('appList.appScreenshot')">
          <div class="flex">
            <el-upload
              action="null"
              accept=".png,.jpg"
              list-type="picture-card"
              :auto-upload="false"
              :on-preview="handlePictureCardPreview"
              :file-list="appImagePathUrl"
              :on-remove="handleRemove"
              :on-change="onFileschange"
            >
              <i class="el-icon-plus"></i>
            </el-upload>

            <div class="tips">
              <p>{{ $t('appList.explain') }}:</p>
              <p>{{ $t('appList.appScreenshotExplain') }}</p>
            </div>
          </div>
        </el-form-item>

        <div class="uploadWrap">
          <el-form-item :label="$t('uploadFiles')" prop="apk">
            <el-upload
              accept=".apk,.exe,.msi"
              action="null"
              :on-change="file => onFilechange(file, 'apk')"
              :on-remove="() => (form.apk = '')"
              :show-file-list="true"
              :multiple="false"
              :auto-upload="false"
              ref="fileUpload"
              name="xls"
            >
              <el-button @click="$refs.fileUpload.clearFiles()" type="primary">{{
                $t('deviceModal.selectFile')
              }}</el-button>
            </el-upload>
          </el-form-item>

          <!-- <el-checkbox @click.stop style="margin-left:58px;margin-top:8px" v-model="form.grayScale"
            >灰度测试版本</el-checkbox
          > -->
        </div>

        <el-form-item :label="$t('groupManagement.selectUser')">
          <el-radio-group v-model="form.power">
            <el-radio :label="0">{{ $t('customerHome.allUsers') }}</el-radio>
            <el-radio :label="1">{{ $t('customerHome.specifyUser') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.power === 1" label="">
          <el-transfer
            v-model="powerUsers"
            :titles="[$t('customerHome.userList'), $t('customerHome.selectedUsers')]"
            :data="powerUsersList"
          >
            <div slot-scope="{ option }">
              <span :title="option.label" class="name">{{ option.label }}</span>
              <span>{{ option.phone }}</span>
            </div>
          </el-transfer>
        </el-form-item>

        <!-- <el-form-item label="设备自动更新版本">
          <el-switch
            v-model="form.automaticUpdate"
            active-color="#ef2b23"
            inactive-color="#eaecf0"
          />
        </el-form-item> -->

        <el-form-item :label="$t('appList.versionDesc')" prop="versionDesc">
          <el-input
            type="textarea"
            rows="4"
            resize="none"
            v-model="form.versionDesc"
            :placeholder="$t('appList.enterVersionDesc')"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">{{ $t('cancel') }}</el-button>
        <el-button :disabled="loading === true" type="primary" @click="submitForm(form)">{{
          $t('save')
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog v-elDragDialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    const rules = {
      icon: [{ required: true, message: this.$t('appList.enterAppIcon') }],

      appName: [
        { required: true, message: this.$t('appList.enterAppName') },
        { max: 50, message: this.$t('appList.maxLength'), trigger: 'blur' }
      ],

      appImageFiles: [{ required: true, message: this.$t('deviceModal.pleaseUploadFile') }],
      apk: [{ required: true, message: this.$t('appList.uploadaApk') }],
      version: [{ required: true, message: this.$t('customerHome.enterAppVersion') }]
    }

    return {
      loading: false,
      fileList: [],
      iconPathUrl: '',
      appIconImageUrl: '',
      dialogImageUrl: '',
      dialogVisible: false,
      form: {
        icon: '',
        appName: '',
        appIntro: '',
        appImageFiles: '',
        file: '',
        apk: '',
        version: '',
        versionDesc: '',
        appDesc: '',
        power: 0
        // grayScale: false,
        // automaticUpdate: false
      },
      rules,
      appImagePathUrl: [],
      appImageUrls: [],
      powerUsersList: [],
      powerUsers: []
    }
  },
  created() {
    this.getUserList()

    if (!this.isCreated) {
      this.rules.icon = undefined
      this.rules.appImageFiles = undefined
      this.rules.apk = undefined
      this.rules = this.rules
    }
    if (this.currentItem && this.currentItem.appId) {
      this.getDetail()
    }
  },
  methods: {
    async getUserList() {
      try {
        const res = await this.$api.systemManagement.userListAll({})

        if (res.result) {
          this.powerUsersList = res.result.map((item, index) => {
            return {
              key: item.uin,
              label: item.name || this.$t('serviceAccountManagement.admin'),
              phone: item.phone
            }
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getDetail() {
      try {
        this.loading = true
        const res = await this.$api.appManagement.appGet({ appId: this.currentItem.appId })
        const item = res.result

        Object.keys(this.form).forEach(key => {
          this.form[key] = item[key]
        })

        if (item.powerUsers) {
          this.powerUsers = item.powerUsers.split(',').map(Number)
        }

        this.$set(this.form, 'version', item.versionCode)
        this.$set(this.form, 'appDesc', item.appDesc)
        this.$set(this.form, 'versionDesc', item.releaseNote)
        this.iconPathUrl = item.iconPathUrl
        this.appImagePathUrl = item.appImagePathUrl.split(',').map(item => ({ url: item }))
        this.appImageUrls = this.currentItem.appImagePathUrl.split(',')
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    onFileschange(res, files) {
      // 排除新增的本地地址（包含 blob:  类似：blob:http://localhost:8085/c5787c66-bdf8-4ee4-af49-a542c99a2684）
      this.appImageUrls = files
        .filter(item => item.url && !item.url.includes('blob:'))
        .map(item => item.url)
      this.form.appImageFiles = files.map(item => item.raw)
    },
    upLoadHandler() {
      this.$refs.upload.submit()
    },
    onFilechange(file, key) {
      if (key === 'icon') {
        this.iconPathUrl = URL.createObjectURL(file.raw)
      }
      if (key == 'apk') {
        //只能上传 .apk ,.exe,.msi文件
        let index = file.raw.name.lastIndexOf('.')
        //获取后缀
        let suffix = file.raw.name.substr(index + 1) || ''
        console.log(suffix)
        if (!['exe', 'apk', 'msi'].includes(suffix.toLowerCase())) {
          this.$refs.fileUpload.clearFiles()
          this.$message.warning(this.$t('appList.uploadFileWarnTip'))
          return
        }
      }
      this.form[key] = file.raw
    },
    handleRemove(file, fileList) {
      // 排除新增的本地地址（包含 blob:  类似：blob:http://localhost:8085/c5787c66-bdf8-4ee4-af49-a542c99a2684）
      this.appImageUrls = fileList
        .filter(item => item.url && !item.url.includes('blob:'))
        .map(item => item.url)
      this.form.appImageFiles = fileList.map(item => item.raw)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    submitForm(data) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true

          try {
            const formData = new FormData()

            for (const i in this.form) {
              if (i === 'appImageFiles' && this.form[i] && this.form[i].length) {
                this.form[i].forEach(item => {
                  formData.append(i, item)
                })
              } else if (i === 'grayScale' || i === 'automaticUpdate') {
                formData.append(i, this.form[i] ? 1 : 0)
              } else {
                formData.append(i, this.form[i])
              }
            }

            if (this.appImageUrls?.length) {
              this.appImageUrls.forEach(item => {
                formData.append('appImageUrls ', item)
              })
            }

            if (this.powerUsers.length) {
              formData.append('powerUsers ', this.powerUsers.join(','))
            }

            if (this.currentItem && this.currentItem.appId) {
              formData.append('appId', this.currentItem.appId)

              await this.$api.appManagement.appUpdate(formData)
              this.$message.success(this.$t('updateSuccess'))
            } else {
              await this.$api.appManagement.appAdd(formData)
              this.$message.success(this.$t('appList.uploadaSuccess'))
            }

            setTimeout(() => {
              this.$emit('getData')
              this.show = false
            }, 100)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-app {
  ::v-deep .el-dialog {
    margin-top: 10vh !important;
  }
}

::v-deep .el-transfer-panel__list .el-checkbox__label {
  div {
    display: flex;

    span {
      word-break: break-word;
    }

    .name {
      width: 90px;
      flex-shrink: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 10px;
    }
  }
}

::v-deep .el-transfer-panel__list .el-checkbox {
  margin-right: 0 !important;
}

::v-deep .el-loading-spinner i {
  font-size: 30px;
}

::v-deep .el-transfer-panel {
  width: 240px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 120px !important;
  height: 120px !important;
  line-height: 120px;
}
::v-deep .el-upload--picture-card {
  width: 120px !important;
  height: 120px !important;
  line-height: 120px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item-status-label i {
  position: absolute;
  left: 14px;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #ef2b23;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.flex {
  display: flex;
  align-items: center;

  .tips {
    line-height: 20px;
    margin-left: 20px;
  }
}

.uploadWrap {
  display: flex;
}
</style>
