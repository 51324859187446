<template>
  <page>
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('groupManagement.groupName')"
        v-model.trim="params.name"
        @keyup.enter.native="replaceData"
      />
      <el-button type="success" plain @click="replaceData">{{ $t('query') }}</el-button>
      <el-button type="danger" plain @click="delHandler(true)">{{ $t('delete') }}</el-button>
    </div>
    <div slot="headerRight">
      <el-button
        v-if="btnShow && btnShow.add"
        type="success"
        @click="
          visible = true
          currentItem = null
        "
        icon="el-icon-plus"
        >{{ $t('newadd') }}</el-button
      >
    </div>

    <div class="table-wrap">
      <el-table
        ref="table"
        @selection-change="handleSelectionChange"
        :data="tableData"
        border
        v-loading="loading"
        height="100%"
      >
        <el-table-column v-if="btnShow && btnShow.delete" type="selection" width="55" />
        <el-table-column
          show-overflow-tooltip=""
          prop="name"
          :label="$t('groupManagement.groupName')"
        />
        <el-table-column
          show-overflow-tooltip=""
          prop="description"
          :label="$t('groupManagement.groupInfo')"
        />
        <el-table-column width="100" prop="count" :label="$t('groupManagement.devicesNumber')">
          <template slot-scope="{ row }">{{ row.deviceTotal }}</template>
        </el-table-column>

        <el-table-column width="280" :label="$t('operation')">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button
                v-if="btnShow && btnShow.manage"
                type="primary"
                size="small"
                @click="manage(row)"
                >{{ $t('groupManagement.manageDevices') }}</el-button
              >
              <el-button
                v-if="btnShow && btnShow.edit && row.id != 201"
                type="primary"
                size="small"
                @click="editHandler(row)"
                >{{ $t('edit') }}</el-button
              >
              <el-button
                v-if="btnShow && btnShow.delete && tableData.length > 1 && row.deviceTotal === 0"
                type="danger"
                size="small"
                @click="delHandler(false, row.id)"
                >{{ $t('delete') }}</el-button
              >

              <el-dropdown trigger="click" @command="commandHandler">
                <el-button @click="currentItem = row" type="primary">
                  {{ $t('more') }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="btnShow && btnShow.turnOffWifi" :command="1">{{
                    $t('deviceList.closeWifi')
                  }}</el-dropdown-item>
                  <el-dropdown-item v-if="btnShow && btnShow.turnOffVirtual" :command="2">{{
                    $t('deviceList.closeVirtualKeys')
                  }}</el-dropdown-item>
                  <el-dropdown-item v-if="btnShow && btnShow.turnOnVirtual" :command="3">{{
                    $t('deviceList.openVirtualKeys')
                  }}</el-dropdown-item>
                  <el-dropdown-item v-if="btnShow && btnShow.timeZone" :command="6">{{
                    $t('deviceList.setTimeZone')
                  }}</el-dropdown-item>
                  <el-dropdown-item v-if="btnShow && btnShow.sleep" :command="7">{{
                    $t('deviceList.setSleep')
                  }}</el-dropdown-item>
                  <el-dropdown-item v-if="btnShow && btnShow.restart" :command="4">{{
                    $t('deviceList.restart')
                  }}</el-dropdown-item>
                  <el-dropdown-item v-if="btnShow && btnShow.shutdown" :command="8">{{
                    $t('deviceList.shutdown')
                  }}</el-dropdown-item>
                  <el-dropdown-item v-if="btnShow && btnShow.recover" :command="5">{{
                    $t('deviceList.restoreFactorySettings')
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <add-group-modal
      @getData="replaceData"
      :currentItem="currentItem"
      :visible.sync="visible"
      v-if="visible"
    />
    <manage-device-modal
      @getData="getData(false)"
      :currentItem="currentItem"
      :visible.sync="visible2"
      v-if="visible2"
    />

    <time-zone-modal
      :groupIds="[currentItem.id]"
      :currentItem="currentItem"
      :visible.sync="visible3"
      v-if="visible3"
    />

    <shut-down-modal
      :visible.sync="visible4"
      :groupIds="[currentItem.id]"
      :currentItem="currentItem"
      v-if="visible4"
    />

    <sleep-modal
      :visible.sync="visible5"
      :groupIds="[currentItem.id]"
      :currentItem="currentItem"
      v-if="visible5"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddGroupModal from './components/AddGroupModal'
import ManageDeviceModal from './components/ManageDeviceModal'
import TimeZoneModal from '../deviceList/components/TimeZoneModal'
import ShutDownModal from '../deviceList/components/ShutDownModal'
import SleepModal from '../deviceList/components/SleepModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddGroupModal,
    TimeZoneModal,
    ShutDownModal,
    SleepModal,
    ManageDeviceModal
  },
  data() {
    return {
      loading: false,
      visible: false,
      visible2: false,
      visible3: false,
      visible4: false,
      visible5: false,
      currentItem: {},
      tableData: [],
      groupList: [],
      multipleSelection: []
    }
  },
  created() {},
  methods: {
    commandHandler(val) {
      if (val === 6) {
        this.visible3 = true
        return
      }

      if (val === 8) {
        this.visible4 = true
        return
      }

      if (val === 7) {
        this.visible5 = true
        return
      }

      let tips = this.$t('deviceList.closeWifi')
      let successTips = this.$t('deviceList.alreadyCloseWifi')
      switch (val) {
        case 1:
          tips = this.$t('deviceList.closeWifi')
          successTips = this.$t('deviceList.alreadyCloseWifi')
          break
        case 2:
          tips = this.$t('deviceList.closeVirtualKeys')
          successTips = this.$t('deviceList.alreadyCloseVirtualKeys')
          break
        case 3:
          tips = this.$t('deviceList.openVirtualKeys')
          successTips = this.$t('deviceList.alreadyOpenVirtualKeys')
          break
        case 4:
          tips = this.$t('deviceList.restart')
          successTips = this.$t('deviceList.restartSuccess')
          break
        case 5:
          tips = this.$t('deviceList.restoreFactorySettings')
          successTips = this.$t('deviceList.restoreFactorySettingsSuccess')
          break
      }

      const groupId = this.currentItem.id

      this.$confirm(`${this.$t('deviceList.wantTo')}${tips}?`, this.$t('tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel')
      })
        .then(async () => {
          const params = {
            groupIds: String(groupId),
            type: val
          }

          this.loading = true

          try {
            const res = await this.$api.deviceManagement.publishRemoteAssistance(params)

            this.$message.success(`${successTips}！`)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      try {
        this.$refs.table && this.$refs.table.clearSelection()
        const res = await this.$api.deviceManagement.groupList(this.params)

        if (res.result) {
          this.tableData = (res.result.list || []).map(item => {
            item.deviceTotal = item.deviceTotal || 0
            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    manage(row) {
      this.visible2 = true
      this.currentItem = row
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map(item => item.id)
    },
    editHandler(row) {
      this.currentItem = row
      this.visible = true
    },

    delHandler(delMore, id) {
      if (delMore && !this.multipleSelection.length) {
        this.$message.warning(this.$t('leastOne'))
        return
      }
      this.$confirm(
        delMore
          ? this.$t('groupManagement.deleteSelectedGroup')
          : this.$t('groupManagement.deleteThisGroup'),
        this.$t('tips'),
        {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        }
      )
        .then(async () => {
          this.loading = true

          try {
            await this.$api.deviceManagement.groupDelete(delMore ? this.multipleSelection : [id])
            this.$message.success(this.$t('deleteSuccess'))
            this.getData(false)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dropdown {
  margin-left: 10px;

}
</style>
